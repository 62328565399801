import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule, HammerModule, Meta } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideRmaTranslation } from '@rma/generic/orc/translation';
import { Environment, provideRmaEnvironment, provideRmaLocale } from '@rma/generic/util/environment';
import { provideRmaLaunchDarklyBrowser } from '@rma/generic/util/feature-flags';
import { provideRmaHttpClient } from '@rma/generic/util/http';
import { provideRmaMaterial } from '@rma/generic/util/material';
import { provideRmaHeap } from '@rma/generic/util/tracking/heap';
import { provideRmaTrackJs } from '@rma/generic/util/tracking/track-js';
import { LazyLoadThirdPartyScriptsService } from '@rma/site/public/orc-layout';
import { Angulartics2Module } from 'angulartics2';
import 'hammerjs';
import { routes } from './app.routes';
import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';

declare let window: { rmaConfig: Environment };

export const config: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserCookiesModule.forRoot(), BrowserModule, Angulartics2Module.forRoot(), HammerModule),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRmaEnvironment({ ...window.rmaConfig }),
    provideRmaHeap(),
    provideRmaHttpClient(false),
    provideRmaLaunchDarklyBrowser(),
    provideRmaLocale(),
    provideRmaMaterial(),
    provideRmaTrackJs(),
    provideRmaTranslation(),
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    Meta,
    LazyLoadThirdPartyScriptsService,
  ],
};
